import React, {useContext, useEffect, useState} from 'react'
import { GlobalState } from '../../GlobalState';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Header from '../utils/Header';
import Topbar from '../utils/Topbar';
import MenuLeft from '../utils/MenuLeft';
import FormCreate from '../utils/FormCreate';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import TextField from '@mui/material/TextField';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { IoMdCloseCircle } from "react-icons/io";

// const dataDefault = {
//     ma:'',
//     ten:'',
//     donvi:''
// }

export default function Donvi() {
    const state = useContext(GlobalState)
    const [token] = state.token
    const [data_donvi, setData_donvi] = useState([])
    const [bac, setBac] = useState([])

    // const [data_submit, setData_submit] = useState(dataDefault)

    const api_get_donvi = process.env.REACT_APP_API_GET_DONVI
    const api_get_bac = process.env.REACT_APP_API_GET_BACDAOTAO

    const [idTen_donvi, setIdTen_donvi] = useState('')
    const [formAdd, setFormAdd] = useState(false)

    useEffect(()=>{
        const getDonvi = async ()=>{
            try {
                const res = await axios.get(`${api_get_donvi}`)
                setData_donvi(res.data)
                
            } catch (err) {
                console.log(err)
            }
        }
        getDonvi()
    },[])

    useEffect(()=>{
        const getBac = async  ()=>{
            try {
                const res = await axios.get(`${api_get_bac}${idTen_donvi}`)
                setBac(res.data)
                
            } catch (err) {
                console.log(err)
            }
        }
        getBac()
    },[idTen_donvi])

    // const handleChange = (e)=>{
    //     const {name, value} = e.target
    //     setData_submit({...data_submit, [name]:value})
    // }

    // const handleSubmit = ()=>{

    // }

    // console.log(bac)

  return (
    <>
        <Topbar/>
        <Header/>

        <div style={{width:'50%', margin:'auto ', marginTop:'20px', marginBottom:'20px'}}>
            <FormControl sx={{width:'80%', backgroundColor:'#f6f6f6'}}>
                <InputLabel id="demo-simple-select-label">Chọn đơn vị</InputLabel>
                <Select
                    size=''
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={idTen_donvi}
                    label="Chọn đơn vị"
                    onChange={(e)=>setIdTen_donvi(e.target.value)}
                >
                    {
                        data_donvi.map(donvi=>(
                            <MenuItem key={donvi.id} value={donvi.title}>{donvi.title}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            <button onClick={()=>setFormAdd(!formAdd)}
                style={{display:`${idTen_donvi!==''&&idTen_donvi!==null?'':'none'}`, padding:'7px 15px', fontSize:'15px', cursor:'pointer', marginLeft:'40px', marginTop:'10px', backgroundColor:'green', border:'none', color:'#fff', borderRadius:'4px'}}>+ Thêm</button>
        </div>

        <div style={{width:'100%'}}>
            <TableContainer component={Paper} sx={{}}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center"><b>STT</b></TableCell>
                            <TableCell align="center"><b>Bậc đào tạo</b></TableCell>
                            <TableCell align="center"><b>Ngày tạo</b></TableCell>
                            <TableCell align="center"><b>Ngày cập nhật</b></TableCell>
                            <TableCell align="center"><b>Tùy chỉnh</b></TableCell> 
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            bac.map((bac,i)=>(
                                <TableRow key={bac.id} sx={{}}>
                                    <TableCell align="center">{i+1}</TableCell>
                                    <TableCell align="center">{bac.ten}</TableCell>
                                    <TableCell align="center">{bac.createdAt.slice(0,10).split('-').reverse().join('/')}</TableCell>
                                    <TableCell align="center">{bac.updatedAt.slice(0,10).split('-').reverse().join('/')}</TableCell>
                                    <TableCell align="center">
                                        <Link to={`/nganh/${idTen_donvi}/${bac.id}`}>Xem ngành</Link>
                                        <FaEdit size={'18px'} style={{margin:'0 20px', cursor:'pointer'}}/>
                                        <MdDelete size={'18px'} style={{cursor:'pointer'}}/>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>

        <div style={{display:`${formAdd?'':'none'}`}}>
            <FormCreate text={'bậc đào tạo'} keys={'bacdaotao'} setFormAdd={()=>setFormAdd(!formAdd)} obj={data_donvi} name_data={idTen_donvi}/>
        </div>

        <MenuLeft/>
    </>
  )
}
