import React, {useContext, useEffect, useState} from 'react'
import { GlobalState } from '../../GlobalState';
import { Link } from 'react-router-dom';

import { FaArrowLeft } from "react-icons/fa6";
import { TbBuildingCommunity } from "react-icons/tb";
import { BsListColumnsReverse } from "react-icons/bs";
import { MdOutlineAssessment } from "react-icons/md";

export default function MenuLeft() {
    const state = useContext(GlobalState)
    const [showMenuBar, setShowMenuBar] = state.showMenuBar

    const showContentPage = ()=>{
        setShowMenuBar(false)
        // window.sessionStorage.setItem('menu-left', false)
    }

  return (
    <div style={{position:'fixed', zIndex:'100', backgroundColor:'#fff', boxShadow:'5px 5px 5px #bbb', top:'0', transform:`${showMenuBar?'translateX(0)':'translateX(-110%)'}`, transition:'linear 0.2s', width:'20%', height:'100vh'}}>
        <div style={{display:'flex', justifyContent:'space-between', padding:'0 15px', marginTop:'10px'}}>
            <p><b><span style={{color:'orange'}}>HUNGHAU EDUCATION</span></b></p>
            <p>
                <FaArrowLeft size={'20px'} onClick={()=>setShowMenuBar(!showMenuBar)} style={{cursor:'pointer'}}/>
            </p>
        </div>

        <div style={{width:'100%'}}>
            <ul style={{listStyle:'none', paddingLeft:'0'}}>
                <Link to={`/danhmuc/bac-nganh-dao-tao`} style={{color:'#000', textDecoration:'none'}}><li onClick={showContentPage}
                    style={{padding:'15px 20px', fontWeight:'bold', display:'flex', cursor:'pointer', borderBottom:'1px solid #bbb', fontSize:'14px'}}><TbBuildingCommunity size={'20px'}/> <span style={{marginLeft:'10px'}}>BẬC - NGÀNH ĐÀO TẠO</span></li></Link>
                <Link to={`/xettuyen/danh-sach-dang-ky`} style={{color:'#000', textDecoration:'none'}}><li onClick={showContentPage}
                    style={{padding:'15px 20px', fontWeight:'bold', display:'flex', cursor:'pointer', borderBottom:'1px solid #bbb', fontSize:'14px'}}><BsListColumnsReverse size={'20px'}/> <span style={{marginLeft:'10px'}}>DANH SÁCH ĐĂNG KÝ</span></li></Link>
                <Link to={``} style={{color:'#000', textDecoration:'none'}}><li onClick={showContentPage}
                    style={{padding:'15px 20px', fontWeight:'bold', display:'flex', cursor:'pointer', borderBottom:'1px solid #bbb', fontSize:'14px'}}><MdOutlineAssessment size={'20px'}/> <span style={{marginLeft:'10px'}}>KẾT QUẢ XÉT TUYỂN</span></li></Link>
            </ul>
        </div>
    </div>
  )
}
