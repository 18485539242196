import axios from 'axios'
import React, {useContext, useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'

export default function Header() {
    const [search, setSeach] = useState('')
    const params = useParams()
    const [tenmuc, setTenmuc] = useState('')

    const [bac, setBac] = useState([])
    const [tenBac, setTenBac] = useState('')

    const api_get_bac = process.env.REACT_APP_API_GET_BACDAOTAO

    useEffect(()=>{
      const getTenBac = async ()=>{
        try {
          const res = await axios.get(`${api_get_bac}${params.title}`)
          setBac(res.data)
          
        } catch (err) {
          console.log(err)
        }
      }
      getTenBac()
    },[])

    useEffect(()=>{
      const setName = ()=>{
        bac.forEach(el=>{
          if(el.id===params.id){
            setTenBac(el.ten)
          }
        })
      }
      setName()
    },[bac])

    useEffect(()=>{
      const get_tenmuc = ()=>{
        const url = params.name
        if(url==='bac-nganh-dao-tao'){
          setTenmuc('Bậc đào tạo')
        }
        else if(url==='danh-sach-dang-ky'){
          setTenmuc('Danh sách đăng ký')
        }

        if(params.title){
          setTenmuc(`${params.title} - ${tenBac}`)
        }
      }
      get_tenmuc()
    },[tenBac])

    // console.log(tenBac)

  return (
    <div style={{backgroundColor:'#039be5', width:'100%', height:'80px', display:'flex', justifyContent:'space-around', padding:'40px 0px'}}>
        <p style={{width:'30%', textAlign:'center', color:'#fff', fontSize:'20px', fontWeight:'bold', marginTop:'10px'}}>{tenmuc}</p>
        <div style={{width:'70%'}}>
            <input type='text' name='search' value={search} onChange={(e)=>setSeach(e.target.value)} placeholder='Nhập nội dung cần tìm...'
                style={{width:'50%', height:'40px', borderRadius:'5px', fontSize:'15px', padding:'0 20px', border:'none', boxShadow:'5px 5px 5px #555'}} />
            <button style={{height:'40px', padding:'0 20px', fontWeight:'bold', cursor:'pointer', marginLeft:'10px', backgroundColor:'#FFCC99', border:'none', borderRadius:'4px', boxShadow:'5px 5px 5px #555'}}>Tìm kiếm</button>
        </div>
    </div>
  )
}
