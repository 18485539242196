import React, {useContext, useEffect, useState} from 'react'
import { GlobalState } from '../../GlobalState';
import { IoMenu } from "react-icons/io5";
import { FaUserCircle } from "react-icons/fa";

export default function Topbar() {
    const state = useContext(GlobalState)
    const [showMenuBar, setShowMenuBar] = state.showMenuBar
    const [nameUser, setnameUser] = useState('')

    useEffect(()=>{
        const getName = ()=>{
            const name = sessionStorage.getItem('nameUser')
            setnameUser(name)
        }
        getName()
    },[])

  return (
    <>
        <div style={{width:'100%', height:'60px', backgroundColor:'#f6f6f6', display:'flex', justifyContent:'space-between', boxShadow:'5px 5px 5px #ccc'}}>
            <div style={{marginLeft:'20px', lineHeight:'60px', cursor:'pointer'}}>
                <IoMenu size={'23px'} onClick={()=>setShowMenuBar(!showMenuBar)}/>
            </div>
            <div style={{marginRight:'20px', display:'flex'}}>
                <p>
                    <FaUserCircle size={'23px'} color='#aaa'/>
                </p>
                <p style={{marginLeft:'10px', paddingTop:'-20px'}}><b>{nameUser}</b></p>
            </div>
        </div>
    </>
  )
}
