import React, {useState, useEffect, useContext} from 'react'
import { GlobalState } from '../../GlobalState';
import { useParams } from 'react-router-dom';
import { IoMdCloseCircle } from "react-icons/io";

import TextField from '@mui/material/TextField';
import axios from 'axios';

export default function FormCreate({text, keys, setFormAdd, obj, name_data}) {
    const state = useContext(GlobalState)
    const [token] = state.token
    const params = useParams()
    const [bac, setBac]= useState([])

    const [ma, setMa] = useState('')
    const [ten, setTen] = useState('')
    const [donvi, setDonvi] = useState('')

    const [maNganh, setMaNganh] = useState('')
    // const [bacdaotao, setBacDaoTao] = useState('')

    const api_get_bac = process.env.REACT_APP_API_GET_BACDAOTAO
    const api_post_bac = process.env.REACT_APP_API_POST_BACDAOTAO
    const api_post_nganh = process.env.REACT_APP_API_POST_NGANH

    useEffect(()=>{
        const getBac = async ()=>{
            try {
                const res = await axios.get(`${api_get_bac}${name_data}`)
                setBac(res.data)
                
            } catch (err) {
                console.log(err)
            }
        }
        getBac()
    },[name_data])

    useEffect(()=>{
        const handle_ma = ()=>{
            let arr = []
            bac.forEach(el=>{
                arr.push(el.ma)
            })
            setMa(`${arr[arr.length-1]+1}`)
        }
        handle_ma()
    },[bac])

    useEffect(()=>{
        const getData = ()=>{
            obj.forEach(el=>{
                if(el.title===name_data){
                    setDonvi(el.id)
                }
            })
        }
        getData()
    },[name_data])

    const handleSubmit = async ()=>{
        if(keys==='bacdaotao'){
            try {
                const res = await axios.post(`${api_post_bac}`, {ma, ten, donvi})
                if(res){
                    alert('Thêm bậc đào tạo thành công!')
                    setFormAdd(false)
                }
                
            } catch (err) {
                console.log(err)
            }
        }
        else if(keys==='nganh'){
            try {
                const res = await axios.post(`${api_post_nganh}`, {ma:maNganh, ten, bacdaotao:params.id})
                if(res){
                    alert('Thêm ngành thành công!')
                    setFormAdd(false)
                }
                
            } catch (err) {
                console.log(err)
            }
        }
    }

    // console.log(maNganh, ten, params.id)
    // console.log(keys, obj, name_data)

  return (
    <>
        <div style={{width:'40%', backgroundColor:'#fff', padding:'20px 20px 30px 20px', boxShadow:'5px 5px 5px #666', position:'absolute', top:'50%', left:'50%', zIndex:'100', transform:'translate(-50%, -50%)', borderRadius:'5px'}}>
            <IoMdCloseCircle onClick={()=>setFormAdd(false)} size={'25px'} style={{position:'absolute', top:'10px', right:'10px', cursor:'pointer', color:'#666'}}/>
            <p style={{textAlign:'center', fontSize:'18px'}}><b>Thêm {text} {name_data}</b></p>
            <TextField size='small' id="outlined-basic" label={`Nhập mã ${text}...`} variant="outlined" 
                value={maNganh} onChange={(e)=>setMaNganh(e.target.value)}
                style={{width:'100%', display:`${keys==='nganh'?'':'none'}`, marginBottom:'10px'}} />
            <TextField size='small' id="outlined-basic" label={`Nhập tên ${text}...`} variant="outlined" 
                value={ten} onChange={(e)=>setTen(e.target.value)}
                style={{width:'100%'}} />
            <button onClick={handleSubmit}
                style={{padding:'7px 15px', fontSize:'15px', cursor:'pointer', marginLeft:'40px', display:'block', margin:'auto', marginTop:'15px', backgroundColor:'green', border:'none', color:'#fff', borderRadius:'4px'}}>
                Thêm
            </button>
        </div>

        <div style={{width:'100%', height:'100vh', backgroundColor:'#000', opacity:'0.4', position:'fixed', top:'0', left:'0'}}></div>
    </>
  )
}
