import React from 'react'

export default function NotFound() {
  return (
    <div style={{padding:'0 20px'}}>
        <h1>Not Found</h1>
        <p>The requested URL was not found on this server.</p>
        <p>Additionally, a 404 Not Found error was encountered while trying to use an ErrorDocument to handle the request.</p>
    </div>
  )
}
