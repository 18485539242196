import React, {useContext, useEffect, useState} from 'react'
import { GlobalState } from '../../GlobalState';
import { Link, useParams } from 'react-router-dom';
import  * as XLSX from 'xlsx';
import axios from 'axios';
import Header from '../utils/Header';
import Topbar from '../utils/Topbar';
import MenuLeft from '../utils/MenuLeft';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

import { FaEdit, FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

export default function Danhsach_xettuyen() {
  const state = useContext(GlobalState)
    const [token] = state.token
    const params = useParams()

    const api_get_thisinh_1 = process.env.REACT_APP_API_GET_THISINH_1
    const api_get_thisinh_2 = process.env.REACT_APP_API_GET_THISINH_2
    const api_get_thisinh_3 = process.env.REACT_APP_API_GET_THISINH_3

    const [danhsach_xettuyen, setdanhsach_xettuyen] = useState([])

    const api_get_donvi = process.env.REACT_APP_API_GET_DONVI
    const [data_donvi, setData_donvi] = useState([])
    const [idTen_donvi, setIdTen_donvi] = useState('')
    const [tu_ngay, setTu_ngay] = useState('')
    const [den_ngay, setDen_ngay] = useState('')

    useEffect(()=>{
        const getDonvi = async ()=>{
            try {
                const res = await axios.get(`${api_get_donvi}`)
                setData_donvi(res.data)
                
            } catch (err) {
                console.log(err)
            }
        }
        getDonvi()
    },[])

    useEffect(()=>{
        if(idTen_donvi){
            window.sessionStorage.setItem('tendonvi', idTen_donvi)
            window.sessionStorage.setItem('tungay', tu_ngay)
            window.sessionStorage.setItem('denngay', den_ngay)
        }

        if(params.name==='danh-sach-dang-ky') {
            if(idTen_donvi!==''&&idTen_donvi!==null&&tu_ngay!==''&&tu_ngay!==null&&den_ngay!==''&&den_ngay!==null)
            getXettuyen()
        }
    },[idTen_donvi, tu_ngay, den_ngay])

    useEffect(()=>{
        if(params){
            setIdTen_donvi(sessionStorage.getItem('tendonvi'))
            setTu_ngay(sessionStorage.getItem('tungay'))
            setDen_ngay(sessionStorage.getItem('denngay'))
        }
    },[params])

    const getXettuyen = async ()=>{
        if(idTen_donvi===''||idTen_donvi===null){
            alert('Chưa chọn tên đơn vị.')
        }
        else if(tu_ngay===''||tu_ngay===null){
            alert('Chưa nhập ngày bắt đầu.')
        }
        else if(den_ngay===''||den_ngay===null){
            alert('Chưa nhập ngày kết thúc.')
        }
        else{
            try {
                const res = await axios.get(`${api_get_thisinh_1}${idTen_donvi}${api_get_thisinh_2}${tu_ngay}${api_get_thisinh_3}${den_ngay}`,{
                    headers: {Authorization: `Bearer ${token}`}
                })
                setdanhsach_xettuyen(res.data)
                
            } catch (err) {
                console.log(err)
            }
        }
    }

    const headers = [
        'Họ và tên đệm', 'Tên', 'Email', 'Điện thoại thí sinh', 'Giới tính', 'Ngày sinh', 'Nơi sinh', 'Dân tộc', 'Tôn giáo', 'CCCD', 'Ngày cấp CCCD', 'Nơi cấp CCCD', 'Điện thoại phụ huynh',
        'Trường đăng ký', 'Chương trình đào tạo', 'Ngành 1', 'Ngành 2', 'Ngày đăng ký',
        'Tỉnh/thành phố thường trú', 'Huyện/Quận thường chú', 'Xã/phường thường trú', 'Địa chỉ thường chú',
        'Tỉnh/thành phố liên lạc', 'Huyện/Quận liên lạc', 'Xã/phường liên lạc', 'Địa chỉ liên lạc', 
        'Tên trường trung học', 'Tỉnh/thành phố trường trung học', 'Huyện/quận trường trung học', 'Học vấn khác', 'Ghi chú',
        'File bằng cấp', 'File CCCD', 'File học bạ'
    ];

    const exportToExcel = () => {
        if(idTen_donvi===''||idTen_donvi===null){
            alert('Chưa chọn tên đơn vị.')
        }
        else if(tu_ngay===''||tu_ngay===null){
            alert('Chưa nhập ngày bắt đầu.')
        }
        else if(den_ngay===''||den_ngay===null){
            alert('Chưa nhập ngày kết thúc.')
        }
        else{
            const data = danhsach_xettuyen.map(item => [
                item.ho,
                item.ten,
                item.email,
                item.dienthoaithisinh,
                item.gioitinh,
                item.ngaysinh.slice(0,10).split('-').reverse().join('-'),
                item.noisinh,
                item.dantoc,
                item.tongiao,
                item.cccd,
                item.ngaycap.slice(0,10).split('-').reverse().join('-'),
                item.noicap,
                item.dienthoaiphuhuynh,
                
                item.truong,
                item.chuongtrinh_nganh_1,
                item.nganh_1,
                item.nganh_2,
                item.createdAt.slice(0,10).split('-').reverse().join('-'),

                item.tinh_tp_thuongtru,
                item.huyen_quan_thuongtru,
                item.xa_phuong_thuongtru,
                item.diachi_thuongtru,

                item.tinh_tp_lienlac,
                item.huyen_quan_lienlac,
                item.xa_phuong_lienlac,
                item.diachi_lienlac,

                item.tentruongtrunghoc_hocvan,
                item.tinh_tp_hocvan,
                item.huyen_quan_hocvan,
                item.khac_hocvan,
                item.ghichu,

                item.image_bangcap,
                item.image_cccd,
                item.image_hocba,
            ]);
    
            data.unshift(headers);
        
            const ws = XLSX.utils.aoa_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        
            XLSX.writeFile(wb, `DanhSachDangKy_${idTen_donvi}_${tu_ngay}_${den_ngay}.xlsx`);
        }
    }

    // console.log(danhsach_xettuyen)
    // console.log(idTen_donvi, tu_ngay, den_ngay)

  return (
    <>
        <Topbar/>
        <Header/>

        <div style={{width:'90%', padding:'20px 0px', margin:'auto'}}>
            <FormControl sx={{width:'20%'}}>
                <InputLabel id="demo-simple-select-label">Chọn đơn vị</InputLabel>
                <Select
                    size='small'
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={idTen_donvi}
                    label="Chọn đơn vị"
                    onChange={(e)=>setIdTen_donvi(e.target.value)}
                >
                    {
                        data_donvi.map(donvi=>(
                            <MenuItem key={donvi.id} value={donvi.title}>{donvi.title}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            <TextField type='date' value={tu_ngay} onChange={(e)=>setTu_ngay(e.target.value)} sx={{}} id="outlined-basic" size='small' label="Từ ngày" variant="outlined" InputLabelProps={{shrink: true,}} style={{width:"20%", margin:'0 20px'}} />
            <TextField type='date' value={den_ngay} onChange={(e)=>setDen_ngay(e.target.value)} sx={{}} id="outlined-basic" size='small' label="Đến ngày" variant="outlined" InputLabelProps={{shrink: true,}} style={{width:"20%"}} />
            <button onClick={getXettuyen} style={{padding:'10px 15px', marginLeft:'20px', cursor:'pointer'}}>Xem</button>
            <button onClick={exportToExcel} style={{padding:'11px 15px',  marginLeft:'20px', cursor:'pointer', backgroundColor:'#0d6736', color:'#fff', border:'none', borderRadius:'5px'}}>Xuất Excel</button>
        </div>
      
        <div style={{width:'100%'}}>
            <TableContainer component={Paper} sx={{}}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center"><b>STT</b></TableCell>
                            <TableCell align="center"><b>Họ tên</b></TableCell>
                            <TableCell align="center"><b>SĐT</b></TableCell>
                            <TableCell align="center"><b>Email</b></TableCell>
                            <TableCell align="center"><b>Giới tính</b></TableCell>
                            <TableCell align="center"><b>CCCD</b></TableCell>
                            <TableCell align="center"><b>Ngày đăng ký</b></TableCell>
                            <TableCell align="center"><b>Tùy chỉnh</b></TableCell> 
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            danhsach_xettuyen.map((dk,i)=>(
                                <TableRow key={dk.id} sx={{}}>
                                    <TableCell align="center">{i+1}</TableCell>
                                    <TableCell align="center">{`${dk.ho} ${dk.ten}`}</TableCell>
                                    <TableCell align="center">{dk.dienthoaithisinh}</TableCell>
                                    <TableCell align="center">{dk.email}</TableCell>
                                    <TableCell align="center">{dk.gioitinh}</TableCell>
                                    <TableCell align="center">{dk.cccd}</TableCell>
                                    <TableCell align="center">{dk.createdAt.slice(0,10).split('-').reverse().join('/')}</TableCell>
                                    <TableCell align="center">
                                        <Link to={`/chitiet/${dk.id}`}><FaEye size={'18px'} style={{cursor:'pointer'}}/></Link>
                                        {/* <FaEdit size={'18px'} style={{cursor:'pointer',  margin:'0 15px'}}/>
                                        <MdDelete size={'18px'} style={{cursor:'pointer'}}/> */}
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>

        <MenuLeft/> 
    </>
  )
}
