import React, {useEffect, useState, useContext} from 'react'
import { useParams, Link } from 'react-router-dom'
import axios from 'axios'
import { GlobalState } from '../../GlobalState'

import Topbar from '../utils/Topbar'
import Header from '../utils/Header'
import MenuLeft from '../utils/MenuLeft'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { FaEye } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

export default function ChiTietThiSinh() {
    const state = useContext(GlobalState)
    const [token] = state.token
    const params = useParams()
    const [data_thisinh, setData_thisinh] = useState([])

    const [detail_thisinh, setDetail_thisinh] = useState([])

    const api_get_thisinh_1 = process.env.REACT_APP_API_GET_THISINH_1
    const api_get_thisinh_2 = process.env.REACT_APP_API_GET_THISINH_2
    const api_get_thisinh_3 = process.env.REACT_APP_API_GET_THISINH_3

    useEffect(()=>{
        if(token){
            getData(sessionStorage.getItem('tendonvi'), sessionStorage.getItem('tungay'), sessionStorage.getItem('denngay'))
        }
    },[token])

    const getData = async (truong, tungay, denngay)=>{
        try {
            const res = await axios.get(`${api_get_thisinh_1}${truong}${api_get_thisinh_2}${tungay}${api_get_thisinh_3}${denngay}`,{
                headers: {Authorization: `Bearer ${token}`}
            })
            setData_thisinh(res.data)
            
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(()=>{
        const getDetail = ()=>{
            data_thisinh.forEach(el=>{
                if(el.id===params.id){
                    setDetail_thisinh(el)
                }
            })
        }
        getDetail()
    },[data_thisinh])

    const keys = Object.keys(detail_thisinh);
    const values = Object.values(detail_thisinh);

    // console.log(keys)

  return (
    <>
        <Topbar/>

        <div style={{width:'100%'}}>
            <TableContainer component={Paper} sx={{}}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableBody>
                        <TableCell colSpan={2} align="center" sx={{boxShadow:'1px 1px 2px #bbb', fontSize:'20px'}}><b>Thông tin thí sinh</b></TableCell>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>Họ Tên</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.ho} {detail_thisinh.ten}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>Email</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.email}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>Điện thoại</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.dienthoaithisinh}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>Giới tính</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.gioitinh}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>Ngày sinh</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.ngaysinh?detail_thisinh.ngaysinh.slice(0,10).split('-').reverse().join('/'):''}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>Nơi sinh</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.noisinh}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>Dân tộc</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.dantoc}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>Tôn giáo</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.tongiao}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>Số CCCD</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.cccd}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>Ngày cấp CCCD</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.ngaycap?detail_thisinh.ngaycap.slice(0,10).split('-').reverse().join('/'):''}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>Nơi cấp CCCD</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.noicap}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>Điện thoại phụ huynh</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.dienthoaiphuhuynh}</TableCell>
                        </TableRow>

                        <TableCell colSpan={2} align="center" sx={{boxShadow:'1px 1px 2px #bbb', fontSize:'20px'}}><b>Thông tin đăng ký</b></TableCell>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>Trường đăng ký</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.truong}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>Chương trình đạo tạo</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.chuongtrinh_nganh_1}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>Ngành 1</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.nganh_1}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>Ngành 2</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.nganh_2}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>Ngày đăng ký</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.createdAt?detail_thisinh.createdAt.slice(0,10).split('-').reverse().join('/'):''}</TableCell>
                        </TableRow>

                        <TableCell colSpan={2} align="center" sx={{boxShadow:'1px 1px 2px #bbb', fontSize:'20px'}}><b>Thông tin liên lạc</b></TableCell>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>Tỉnh/Thành phố thường trú</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.tinh_tp_thuongtru}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>Huyện/Quận thường trú</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.huyen_quan_thuongtru}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>Xã/Phường thường trú</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.xa_phuong_thuongtru}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>Địa chỉ thường trú</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.diachi_thuongtru}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>Tỉnh/Thành phố liên lạc</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.tinh_tp_lienlac}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>Huyện/Quận liên lạc</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.huyen_quan_lienlac}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>Xã/Phường liên lạc</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.xa_phuong_lienlac}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>Địa chỉ liên lạc</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.diachi_lienlac}</TableCell>
                        </TableRow>

                        <TableCell colSpan={2} align="center" sx={{boxShadow:'1px 1px 2px #bbb', fontSize:'20px'}}><b>Thông tin học vấn</b></TableCell>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>Tên trường trung học</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.tentruongtrunghoc_hocvan}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>Tỉnh/Thành phố trường trung học</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.tinh_tp_hocvan}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>Huyện/Quận trường trung học</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.huyen_quan_hocvan}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>Học vấn khác</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.khac_hocvan}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>Ghi chú</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.ghichu}</TableCell>
                        </TableRow>

                        <TableCell colSpan={2} align="center" sx={{boxShadow:'1px 1px 2px #bbb', fontSize:'20px'}}><b>Tệp hồ sơ</b></TableCell>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>File bằng cấp</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.image_bangcap}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>File CCCD</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.image_cccd}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}><b>File học bạ</b></TableCell>
                            <TableCell align="center" sx={{borderRight:'1px solid #bbb'}}>{detail_thisinh.image_hocba}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>

        <MenuLeft/>
    </>
  )
}
