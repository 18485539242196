import React, { useEffect, useState, useContext } from "react";
import { GlobalState } from "../../GlobalState";
import { useMsal } from "@azure/msal-react"
import { InteractionRequiredAuthError } from "@azure/msal-browser"
import { loginRequest } from '../azure/config'
import { useIsAuthenticated } from '@azure/msal-react';
import Loading from '../azure/loading'
import Logo_micro from '../img/microsoft.webp'
import Logo_HHH from '../img/logo-HHH.png'

export default function Login() {
    const state = useContext(GlobalState)

    const { accounts, instance } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const [tokken, setToken] = state.token
    const [Auth, setAuth] = state.isAuthenticated

    // console.log(infor)

    useEffect(()=>{
        if(isAuthenticated){
            // setAuth(isAuthenticated)
            window.sessionStorage.setItem('islogged', true)
        }
    },[isAuthenticated])

    const handleLogin = () => {
        instance.loginPopup(loginRequest)
        .then(response => {
            const account = response.account;
            instance.setActiveAccount(account);
        })
        .catch(e => {
            console.error(e);
        });
    };

    // console.log(accounts)

  return (
    <div style={{backgroundColor:'#f6f6f6', width:'100%', height:'100vh'}}>
        <div style={{border:'1px solid #bbb', boxShadow:'8px 8px 15px #ccc', position:'absolute', top:'50%', left:'50%', transform:'translate(-50%, -50%)', borderRadius:'5px', padding:'20px 50px', backgroundColor:'#fff'}}>
            <img src={Logo_HHH} alt="Logo Hung Hau" style={{width:'100px', margin:'auto', display:'block'}}/>
            <p style={{fontWeight:'bold', textAlign:'center', fontSize:'20px'}}>Đăng nhập</p>
            <button onClick={handleLogin} style={{display:'flex', justifyItems:'center', cursor:'pointer', margin:'auto', padding:'0px 20px', marginTop:'20px'}}>
                {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" className='h-8'><path fill="#f3f3f3" d="M0 0h23v23H0z"/><path fill="#f35325" d="M1 1h10v10H1z"/><path fill="#81bc06" d="M12 1h10v10H12z"/><path fill="#05a6f0" d="M1 12h10v10H1z"/><path fill="#ffba08" d="M12 12h10v10H12z"/></svg> */}
                <img src={Logo_micro} alt="Logo Microsoft" style={{width:'35px', marginTop:'11px'}}/>
                <p style={{marginLeft:'10px', fontSize:'18px', color:'#555'}}>Login with Office 365</p>
            </button>
        </div>
    </div>
  )
}
