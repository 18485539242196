import React, {useContext, useEffect, useState} from 'react'
import { GlobalState } from '../../GlobalState';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Header from '../utils/Header';
import Topbar from '../utils/Topbar';
import MenuLeft from '../utils/MenuLeft';
import FormCreate from '../utils/FormCreate';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

export default function Index() {
  const state = useContext(GlobalState)
  const [ctdt , setCtdt] = useState([])
  const [value_ctdt, setValue_ctdt] = useState('')
  const [nganh, setNganh] = useState([])
  const params = useParams()

  const [formAdd, setFormAdd] = useState(false)

  const api_get_nganh = process.env.REACT_APP_API_GET_NGANH

  useEffect(()=>{
    if(params.id){
        const get_nganh = async ()=>{
          const res = await axios.get(`${api_get_nganh}${params.id}`)
          setNganh(res.data)
        }
        get_nganh()
    }
  },[params.id])

  // console.log(api_get_nganh,params.title)

  return (
    <>
        <Topbar />
        <Header value_ctdt={value_ctdt} ctdt={ctdt} />

        <div style={{width:'100%'}}>
            <button style={{padding:'7px 15px', fontSize:'15px', cursor:'pointer', margin:'auto', display:'block', marginTop:'10px', marginBottom:'10px', backgroundColor:'green', border:'none', color:'#fff', borderRadius:'4px'}}
                onClick={()=>setFormAdd(!formAdd)}>+ Thêm</button>
            
            <TableContainer component={Paper} sx={{}}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center"><b>STT</b></TableCell>
                            <TableCell align="center"><b>Mã ngành</b></TableCell>
                            <TableCell align="center"><b>Tên ngành</b></TableCell>
                            <TableCell align="center"><b>Ngày tạo</b></TableCell>
                            <TableCell align="center"><b>Ngày cập nhật</b></TableCell>
                            <TableCell align="center"><b>Tùy chỉnh</b></TableCell> 
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                          nganh.map((nganh,i)=>(
                            <TableRow key={nganh.id} sx={{cursor:'pointer'}}>
                                <TableCell align="center">{i+1}</TableCell>
                                <TableCell align="center">{nganh.ma}</TableCell>
                                <TableCell align="center">{nganh.ten.toUpperCase()}</TableCell>
                                <TableCell align="center">{nganh.createdAt.slice(0,10).split('-').reverse().join('/')}</TableCell>
                                <TableCell align="center">{nganh.updatedAt.slice(0,10).split('-').reverse().join('/')}</TableCell>
                                <TableCell align="center">
                                    <FaEdit size={'18px'} style={{marginRight:'10px', cursor:'pointer'}}/>
                                    <MdDelete size={'18px'} style={{cursor:'pointer'}}/>
                                </TableCell>
                            </TableRow>
                          ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>

        <div style={{display:`${formAdd?'':'none'}`}}>
            <FormCreate text={'ngành'} keys={'nganh'} setFormAdd={()=>setFormAdd(!formAdd)} obj={nganh} name_data={value_ctdt}/>
        </div>

        <MenuLeft/>
    </>
  )
}
