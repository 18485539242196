import React, {useContext, useEffect, useState} from 'react'
import { GlobalState } from '../../GlobalState';
import Header from '../utils/Header';
import Topbar from '../utils/Topbar';
import MenuLeft from '../utils/MenuLeft';

import { IoMenu } from "react-icons/io5";
import { FaUserCircle } from "react-icons/fa";
import { FaArrowLeft } from "react-icons/fa6";
import { TbBuildingCommunity } from "react-icons/tb";

import Donvi from '../donvi/Donvi';
import Background from '../img/bg1.png'

export default function MainPage() {
    const state = useContext(GlobalState)
    const [infor] = state.infor
    const [token] = state.token

    const [showMenuBar, setShowMenuBar] = state.showMenuBar

    const [tenMuc, setTenMuc] = useState('')

    const [donvi, setDonvi] = useState(false)

    // useEffect(()=>{
    //     const getName = ()=>{
    //         infor.forEach(el=>{
    //             sessionStorage.setItem('nameUser', el.name)
    //         })
    //     }
    //     getName()
    // },[infor])

    // const showContentPage = (id, ten_muc)=>{
    //     setTenMuc(ten_muc)
    //     setShowMenuBar(false)

    //     switch(id){
    //         case 1:
    //             setDonvi(true)
    //             break;
    //     }
    // }

    // console.log(nameUser)
    // console.log(token)

  return (
    <>
        <Topbar />
        <MenuLeft />
        <img src={Background} alt='ảnh nền' style={{width:'100%', height:'100vh', marginTop:'-0px', position:'absolute', top:'0', left:'0', zIndex:'-1'}}/>
    </>
  )
}
