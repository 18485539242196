import React, {useContext, useEffect, useState} from 'react'
import { GlobalState } from '../../GlobalState';
import { Routes, Route } from 'react-router-dom';
import Login from '../login/Login';
import MainPage from '../mainPage/MainPage';
import CTDT_Nganh from '../bac_nganh';
import Donvi from '../donvi/Donvi';
import Danhsach_xettuyen from '../danhsach_xettuyen/Danhsach_xettuyen';
import ChiTietThiSinh from '../danhsach_xettuyen/ChiTietThiSinh';
import NotFound from '../utils/NotFound'

export default function Router({isMobile}) {
  const state = useContext(GlobalState)
  const [islogged] = state.isAuthenticated

  return (
    <Routes>
        <Route path="/" element={islogged ? <MainPage /> : <Login />} />
        <Route path="/nganh/:title/:id" element={islogged ? <CTDT_Nganh /> : <NotFound/>} />
        <Route path="/danhmuc/:name" element={islogged ? <Donvi /> : <NotFound/>} />
        <Route path="/xettuyen/:name" element={islogged ? <Danhsach_xettuyen /> : <NotFound/>} />
        <Route path="/chitiet/:id" element={islogged ? <ChiTietThiSinh /> : <NotFound/>} />

        <Route path="*" element={<NotFound/>} />
    </Routes>
  )
}
