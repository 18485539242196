import React from "react";

function Loading(params) {
  return (
    <div>
      <h2>Loading....</h2>
    </div>
  )
}

export default Loading