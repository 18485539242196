import React, {useEffect, useState} from "react";
import {BrowserRouter as Router} from 'react-router-dom';
import Page from "./components/router/Router";
import {DataProvider} from './GlobalState';

function App() {
  // độ dài , rộng màn hình
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)

  useEffect(() => {
      const handleResize = () => {
          setWindowSize({
              width: window.innerWidth,
              height: window.innerHeight
          });
      };

      window.addEventListener('resize', handleResize);

      return () => window.removeEventListener('resize', handleResize);
  },[]);

  useEffect(() => {
      setIsMobile(windowSize.width < 768)
  }, [windowSize]);

  return (
    <DataProvider>
      <Router>
        <div className="App">
          <Page isMobile={isMobile}/>
        </div>
      </Router>
    </DataProvider>
  );
}

export default App;
