import React, {createContext, useState, useEffect} from 'react';

import { useMsal } from "@azure/msal-react"
import { InteractionRequiredAuthError } from "@azure/msal-browser"
import { useIsAuthenticated } from '@azure/msal-react';

export const GlobalState = createContext();

export const DataProvider = ({children}) =>{
    const [token, setToken] = useState(false)
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [infor, setInfor] = useState(false)
    const [showMenuBar, setShowMenuBar] = useState(true)

    const { accounts, instance } = useMsal();
    const isAuthenticated2 = useIsAuthenticated();

    useEffect(()=>{
        setIsAuthenticated(window.sessionStorage.getItem('islogged'))
        // setShowMenuBar(window.sessionStorage.getItem('menu-left'))
    })

    useEffect(() => {
        const fetchToken = async () => {
            if (accounts.length > 0) {
                instance.setActiveAccount(accounts[0]);
            }
    
            const accessTokenRequest = {
                scopes: ["api://64108941-8476-4d57-8f86-a75ac1919844/User", "offline_access"],
                account: instance.getActiveAccount()
            };
    
            if (accessTokenRequest.account) {
                try {
                    const response = await instance.acquireTokenSilent(accessTokenRequest);
                    const accessToken = response.accessToken;
                    setToken(accessToken);
                } catch (error) {
                    console.error("Error acquiring access token:", error);
                    if (error instanceof InteractionRequiredAuthError) {
                        try {
                            const response = await instance.acquireTokenPopup(accessTokenRequest);
                            const accessToken = response.accessToken;
                            setToken(accessToken);
                        } catch (popupError) {
                            console.error("Error acquiring access token with popup:", popupError);
                        }
                    }
                }
            }
    
            accounts.forEach(el => {
                sessionStorage.setItem('nameUser', el.name);
            });
        };
    
        fetchToken();
    }, [accounts, instance, isAuthenticated2, isAuthenticated]);

    // console.log(token)

    const state = {
        token: [token, setToken],
        isAuthenticated: [isAuthenticated, setIsAuthenticated],
        infor: [infor, setInfor],
        showMenuBar: [showMenuBar, setShowMenuBar]
    }

    return(
        <GlobalState.Provider value = {state}>
            {children}
        </GlobalState.Provider>
    )
}